import { faArrowsAltH, faBed } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image";
import React, {useEffect, useState} from "react";
import '../Card/Card.css'
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import CardSeller from "../Card/CardSeller/CardSeller";

const CardSpain = (props) => {
    
    const data = props.data
    
    const priceInt = parseInt(data.villas.prijs)
    const priceCurr = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(priceInt).replace(',00', ',-');
   
    const length = props.length

    const price = `${priceCurr}`

    const key = props.delay
    const animDelay = key !== undefined ? key * 0.15 : 0

    const styles = props.styles

    function isScrolled() {
        if (typeof window === 'undefined') return false;
        return window.scrollY > 100;
    }

    const scroll = isScrolled();

    const handleVillaCardClick = () => {
        if (typeof window !== 'undefined') {
            sessionStorage.setItem('scrollPosition', window.scrollY);
        }
    }

    console.log(scroll)
    
    return(
        <>
        <div className="h-full" onClick={handleVillaCardClick}>
        <Link to={`/spanje/${data.slug}`}>
            <motion.div layoutId={key} initial={{translateY: scroll ? 0 : 75, opacity: scroll ? 1 : 0}} animate={{translateY: 0, opacity: 1}} transition={{duration: 0.2, delay: animDelay }} className={` card ${length > 3 ? ``: `max-h-8/12`} h-full p-0 transition-all duration-300 ease-in ${styles} col-span-1 row-span-1 relative `} >
                    <div className="object-cover w-full h-3/6 m-0 image_container ">
                        <div className="flex row ">
                            <div className="max-h-full w-full">
                                {data.villas.afbeeldingen[0].afbeelding !== null ?
                                <img src={data.villas.afbeeldingen[0].afbeelding} alt={`Woning aan de ${data.title}`} className={`h-48 max-h-2/6 object-cover w-full mb-0 z-10`}></img>
                                : 
                                null
                                }
                            </div>
                        </div>
                        <div className="flex flex-row p-5 bg-light justify-between w-full">
                            <div className="flex flex-col h-full w-full">
                                <h3 className="text-blue text-lg m-0 mb-2">{data.villas.plaats}</h3>
                                <span className="text-blue opacity-40 text-sm block"><span>{data.villas.provincie}</span></span>
                                <span className="text-blue text-sm my-3">{data.villas.oppervlakten.bouw}m² {data.villas.bedden !== "" ? <span className="mx-1">&bull;</span> : null} <span>{data.villas.bedden} {data.villas.bedden !== "" ? 'kamers' : ''}</span></span>
                                
                                <div className="flex flex-row items-end lg:items-center justify-between">
                                    <h3 className="text-blue text-sm lg:text-xl mx-0 mb-3 mt-2">{price}</h3>
                                    <div className="flex flex-row">
                                        <img src={'https://beheer.groenhoutmakelaars.nl/wp-content/uploads/2022/01/IMGL8754-marloesfotografie.jpg'} alt={``} className="rounded-full m-0 lg:mr-3 -mr-3 object-cover lg:w-16 w-16 lg:h-16 h-16" style={{zIndex: '2'}} ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </motion.div>
        </Link>
        </div>
        </>
    )
}

export default CardSpain;